import _ from 'lodash';
import { combineReducers } from 'redux';
import moment from 'moment';
import uuid from 'uuid';
/** import third-party libraries in the section above, keep the ascending order */

import schema from './schema';
import {
    shouldShowFrequencyCapWarningOnChange,
    updateFrequencyCapWarning,
    calculateAudienceFee,
    formatExchangesBlocked,
    isTacticsEnabled,
    isAdUsingDisplayCreative,
} from 'states/resources/ads/business-logic';
import {
    calculateThirdPartyFees,
    revenueModelToBillingTerm,
} from 'states/resources/campaigns/business-logic';
import { validateAdDraft, updateBudgetFields } from './services';

import poiViewer from './reducer-poi-viewer';
import {
    changeWeight,
    handleCreativesModeSwitch,
    handleSelectEvenCreative,
    handleClearCreatives,
    handleUpdateCreativeScheduleRow,
    handleAddCreativeScheduleRow,
    handleMultiClearCreatives,
    handleRemoveCreativeScheduleRow,
    handleSelectWeightedCreative,
    changeScheduledWeightedCreativeSelection,
    changeScheduledWeightedCreativeWeight,
    changeScheduledEvenWeighted,
    addCreativeToScheduledWeightedCreative,
    removeScheduledWeightedCreative,
    changeScheduledWeightedCreativeEndDate,
    addScheduledWeightedCreativeDateRange,
    removeScheduledWeightedCreativeDateRange,
    syncScheduleRotationWithFlight,
} from 'states/resources/creatives/business-logic';
import { CampaignTypeMapping } from 'states/resources/campaigns/business-logic';
import {
    canAdPresetFieldBeUsed,
    getAdPresetValuesToApply,
} from 'states/resources/ad-presets/business-logic';

import platformChangeHandler from './modules/platform/handlers';
import { handleChangeIasGroupmViewability } from './modules/ias-groupm-viewability/handlers';
import { handleChangeIasPublicisViewability } from './modules/ias-publicis-viewability/handlers';
import {
    handleChangeIASContextualTargeting,
    deriveIASContextualTargeting,
} from './modules/ias-contextual-targeting/handlers';
import {
    handleChangeIASFraudPreventionRisk,
    handleChangeIASFraudPreventionUnrateable,
    handleChangeIASFraudPreventionIp,
    deriveIASFraudPrevention,
} from './modules/ias-fraud-prevention/handlers';
import { handleIASViewabilityChange } from './handlers/ias-viewability';

import {
    handleIASBrandSafetyChange,
    deriveIASBrandSafety,
} from './modules/ias-brand-safety/handlers';

import {
    getAudienceWarning,
    getDeviceOsWarning,
    getCarrierISPWarning,
    getSingleRotationCreativeWarning,
    getEvenRotationCreativeWarning,
    getWeightedRotationCreativeWarning,
    getScheduledRotationWarning,
    getExchangeTargetingWarning,
} from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';

import { DECLARED_AGE_SEGMENTS } from './constants';

import { reducer as reducerV2 } from './v2/reducer';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';

const initialState = {
    draft: null,
    isDraftValid: false,
    errors: [],
    showErrors: false,
    isLoading: true,
    isSubmitting: false,
    isAdPresetModalOpen: false,
    isDirty: false,
    startNow: false,
    unassignedPoints: [], // @TODO to remove
    isOpen: false,
    poiViewer: null,
    searchString: '',
    geoCategories: null,
    scheduledRotationWarningEnabled: false,
    inventoryPredictionLoading: true,
    inventoryPredictionError: '',
    dailyAvailableImpressions: 0,
    dailyUniqueUsers: 0,
    adPresetKeyCounter: 0,

    shouldShowFrequencyCapWarningOnChange: true,
    showFrequencyCapWarning: false,

    moduleErrors: {},
    isPoiRecategorized: false,
    validGeoLayers: {},
    visiblePanel: {},
    warnings: {},
    isWarningModalOpen: false,
    advertiser: null,
};

export const processCreativeWarning = ({
    creativeIds,
    rotation_rules,
    creativeMapping,
    adPlatforms,
}) => {
    const creativeData = _.map(creativeIds, id => creativeMapping[id]).filter(creative => creative);

    if (_.size(creativeData) > 0) {
        if (rotation_rules.mode === CreativeRotationMethodMapping.Single) {
            return getSingleRotationCreativeWarning({
                adPlatforms,
                creative: creativeData[0],
            });
        } else if (
            rotation_rules.mode === CreativeRotationMethodMapping.Even ||
            rotation_rules.mode === CreativeRotationMethodMapping.OptimizedForClicks ||
            rotation_rules.mode === CreativeRotationMethodMapping.OptimizedForConversions ||
            rotation_rules.mode === CreativeRotationMethodMapping.OptimizedForVideoCompletion
        ) {
            return getEvenRotationCreativeWarning({
                adPlatforms,
                creatives: creativeData,
            });
        } else if (rotation_rules.mode === CreativeRotationMethodMapping.Weighted) {
            const creativeDataWithWeights = _.map(rotation_rules.weighted, creative => ({
                ...creativeMapping[creative.markup_id],
                weight: creative.weight,
            }));
            return getWeightedRotationCreativeWarning({
                adPlatforms,
                creatives: creativeDataWithWeights,
            });
        }
    }
    if (rotation_rules.mode === CreativeRotationMethodMapping.Weather) {
        const creativeDataFromWeather = _(rotation_rules.weather)
            .map(rotationData => creativeMapping[rotationData.markupId])
            .filter(creative => creative)
            .value();

        return getEvenRotationCreativeWarning({
            adPlatforms,
            creatives: creativeDataFromWeather,
        });
    }

    if (rotation_rules.mode === CreativeRotationMethodMapping.Scheduled) {
        const scheduledCreatives = _.get(
            rotation_rules,
            CreativeRotationMethodMapping.Scheduled,
            []
        );
        return getScheduledRotationWarning({
            adPlatforms,
            scheduledCreatives,
            creativeMapping,
        });
    }
    return {};
};

export const getTacticWarnings = ({
    tactics,
    audienceMapping,
    adPlatforms,
    flexSegmentMapping,
    geotargets,
    restrictedCategory,
    generalErrors,
    isCTVCampaign,
    isDOOHCampaign,
    orgId,
}) => {
    const tacticWarnings = {};

    // We want to check if we're targeting Quebec, a city in Quebec or all
    // of Canada without excluding Quebéc. If either of these are the case
    // then we should show a warning in case the campaign has restricted
    // category set to Cannabis.
    const isTargetingCanada = geotargets && geotargets.find(target => target.country === 'CA');
    const isTargetingQuebec =
        isTargetingCanada &&
        isTargetingCanada.include.length &&
        isTargetingCanada.include.find(region => region.region === 'CA-QC');
    const isExcludingAllQuebec =
        isTargetingCanada &&
        isTargetingCanada.exclude.length &&
        isTargetingCanada.exclude.find(region => region.value === 'Quebec');

    let has19to24AgeSegment = false;
    let allTacticsHaveDeclaredAgeSegments = false;
    let hasTacticWithOrDeclaredAge = false;
    _.forEach(tactics, tactic => {
        let tacticHasAgeSegment = false;

        const audienceSegmentModules = _.filter(
            tactic.targeting,
            targetingModule => targetingModule.module === 'audience_segments'
        );

        tacticWarnings[tactic.id] = {
            title: tactic.title,
            modules: _.map(audienceSegmentModules, module => module.id),
        };

        _.forEach(audienceSegmentModules, module => {
            const includedAudienceIds = _.get(module, 'draft.audiences');
            const flexCategories = _.get(module, 'draft.geo_targeting_settings.categories');
            const flexCategoryLayers = _.get(
                module,
                'draft.geo_targeting_settings.category_layers'
            );
            const flexCustomLayers = _.get(module, 'draft.geo_targeting_settings.custom_layers');

            const allFlexSegments = _.concat(
                flexCategories,
                flexCategoryLayers,
                flexCustomLayers
            ).filter(segment => segment);

            const audiencesData = _.map(includedAudienceIds, id => audienceMapping[id]);
            const flexSegmentData = _.map(allFlexSegments, flexId => flexSegmentMapping[flexId]);

            const allMappedSegments = _.concat(audiencesData, flexSegmentData);

            const warningData = getAudienceWarning({
                adPlatforms,
                allMappedSegments,
                isCTVCampaign,
                isDOOHCampaign,
                orgId,
            });

            if (restrictedCategory === 'cannabis') {
                if (!has19to24AgeSegment && (isTargetingQuebec || !isExcludingAllQuebec)) {
                    has19to24AgeSegment =
                        module.draft.audiences.length &&
                        !!module.draft.audiences.find(
                            segment => segment === 'pds_fe6ef01a-756a-4ad2-8921-1ed577448584'
                        );
                }

                if (!tacticHasAgeSegment) {
                    tacticHasAgeSegment =
                        module.draft.audiences.length &&
                        !!module.draft.audiences.find(segment =>
                            DECLARED_AGE_SEGMENTS.includes(segment)
                        );
                }

                if (tacticHasAgeSegment && !hasTacticWithOrDeclaredAge) {
                    hasTacticWithOrDeclaredAge =
                        module.draft.audiences.length > 1 &&
                        !!module.draft.audiences.find(
                            segment => !DECLARED_AGE_SEGMENTS.includes(segment)
                        );
                }
            }

            tacticWarnings[tactic.id][module.id] = {
                audience_segments: warningData,
            };
        });

        allTacticsHaveDeclaredAgeSegments = tacticHasAgeSegment;
    });

    if (
        restrictedCategory === 'cannabis' &&
        ((isTargetingCanada && !allTacticsHaveDeclaredAgeSegments) ||
            has19to24AgeSegment ||
            hasTacticWithOrDeclaredAge)
    ) {
        tacticWarnings.hasWarning = true;
        tacticWarnings.message =
            'All cannabis campaigns must use Declared Age audiences to restrict targeting by age groups. Declared Age audiences use first-party data from The Weather Network users who voluntarily completed our demographics survey. Minimum age of cannabis use varies by province (Alberta: 18+; Quebec: 21+; rest of Canada: 19+).';
        tacticWarnings.severity = 'hard';
    }

    if (generalErrors && generalErrors.tactics_generators) {
        tacticWarnings.hasWarning = true;
        tacticWarnings.message = generalErrors.tactics_generators;
        tacticWarnings.severity = 'hard';
    }

    return tacticWarnings;
};

const middleware = [
    {
        description: 'initialize state',
        target(state, action) {
            return 'AD_FORM__INIT_STATE' === action.type;
        },
        reduce() {
            return initialState;
        },
    },
    {
        description: 'open ad form',
        target(state, action) {
            return 'AD_FORM__OPEN' === action.type;
        },
        reduce(state) {
            return {
                ...state,
                isLoading: true,
                isOpen: true,
            };
        },
    },
    {
        description: 'initialize geo points',
        target(state, action) {
            return 'AD_FORM__INIT_GEO_POINTS' === action.type;
        },
        reduce(state, action) {
            // add a temp pointId for tracking adding and deleting points from session
            const unassignedPoints = _.map(action.payload.points, point => {
                return {
                    ...point,
                    pointId: _.uniqueId(),
                };
            });
            return {
                ...state,
                unassignedPoints,
            };
        },
    },
    {
        description: 'initialize form',
        target(state, action) {
            return 'AD_FORM__INIT' === action.type;
        },
        reduce(state, action) {
            const {
                campaign,
                ad,
                rawAd,
                geoCategories,
                points,
                adId,
                ownOrganization,
                useTactics,
                audiences,
                stats,
                creatives,
                applists,
                defaultBlacklists,
                defaultWhitelists,
                techFee,
                duplicate,
                dealsByOrganization,
                ftaLocationLists,
                campaignBudgetAllocationMethod,
                campaignCarriersISPsVersion,
                conversions,
                isCrossPlatformCampaign,
                flexSegments,
                advertiser,
            } = action.payload;

            if (!geoCategories) {
                return state;
            }

            let sessionDraft_next = { ...ad };

            if (useTactics) {
                sessionDraft_next = {
                    ...sessionDraft_next,
                    tactics_enabled: true,
                };
            }

            const currentAd = action.payload.adId ? rawAd : null;

            const validGeoLayers = getValidGeoLayers(geoCategories.geoLayers);

            // poi module
            const geoCategories_current = _.get(initialState, 'geoResources', null);
            let geoCategories_next = geoCategories_current;
            if (geoCategories_current === null) {
                // Only inititialize session's draft if there isn't already one.
                // This is to prevent router reruning when user navigates to edit creative form.
                geoCategories_next = geoCategories;
            }

            // add a temp pointId for tracking adding and deleting points from session
            const unassignedPoints = _.map(points, point => {
                return {
                    ...point,
                    pointId: _.uniqueId(),
                };
            });

            let inventoryPredictionError = '';

            const audiencesMapping = {};
            _.each(audiences, audience => {
                audiencesMapping[audience.id] = audience;
            });

            const flexSegmentMapping = {};
            _.each(flexSegments, segment => {
                flexSegmentMapping[segment.id] = segment;
            });

            const audienceSourceTypeRates = {};
            _.each(campaign.audience_rates, rate => {
                audienceSourceTypeRates[rate.name] = rate.fee;
            });

            const mode = adId ? 'edit' : 'new';

            let visiblePanel;

            if (sessionDraft_next) {
                const poiHasValue =
                    sessionDraft_next.geo_targeting_settings.categories.length > 0 ||
                    sessionDraft_next.geo_targeting_settings.category_layers.length > 0 ||
                    sessionDraft_next.geo_targeting_settings.custom_layers > 0;
                const appCatHasValue =
                    sessionDraft_next.target_app_categories.length > 0 ||
                    sessionDraft_next.target_app_store_cat.length > 0 ||
                    sessionDraft_next.target_app_store_cat_exclude.length > 0 ||
                    sessionDraft_next.target_iab_categories.length > 0 ||
                    sessionDraft_next.target_iab_categories_exclude.length > 0;
                const viewabilityHasValue =
                    sessionDraft_next.ias.viewability.value > 0 ||
                    sessionDraft_next.ias.groupmViewability.value > 0 ||
                    sessionDraft_next.ias.publicisViewability.value > 0;
                const brandSafetyHasValue =
                    sessionDraft_next.ias.brandSafety.adult.value > 0 ||
                    sessionDraft_next.ias.brandSafety.alcohol.value > 0 ||
                    sessionDraft_next.ias.brandSafety.gambling.value > 0 ||
                    sessionDraft_next.ias.brandSafety.hateSpeech.value > 0 ||
                    sessionDraft_next.ias.brandSafety.illegalDownloads.value > 0 ||
                    sessionDraft_next.ias.brandSafety.illegalDrugs.value > 0 ||
                    sessionDraft_next.ias.brandSafety.offensiveLanguage.value > 0 ||
                    sessionDraft_next.ias.brandSafety.violence.value > 0 ||
                    sessionDraft_next.ias.brandSafety.unrateable.value > 0;

                const isIasPostBidVerificationEnabledHasValue = _.isBoolean(
                    sessionDraft_next.isIasPostBidVerificationEnabled
                );

                visiblePanel = {
                    poi: poiHasValue,
                    tactics: sessionDraft_next.tactics_generators.length > 0,
                    thirdPartyFees: sessionDraft_next.thirdPartyFees.length > 0,
                    appCategories: appCatHasValue,
                    geo: sessionDraft_next.geoboxes.length > 0,
                    audienceSegments: sessionDraft_next.audiences.length > 0,
                    viewability: viewabilityHasValue,
                    brandSafety: brandSafetyHasValue,
                    postBidVerification: isIasPostBidVerificationEnabledHasValue,
                };
            } else {
                visiblePanel = {
                    poi: false,
                    tactics: false,
                    thirdPartyFees: false,
                    appCategories: false,
                    geo: false,
                    audienceSegments: false,
                    viewability: false,
                    brandSafety: false,
                };
            }

            if (mode === 'new' && !duplicate) {
                sessionDraft_next.blacklistedAppsAndSites = defaultBlacklists;
                sessionDraft_next.whitelistedAppsAndSites = defaultWhitelists;
                sessionDraft_next.rotation_rules.mode = CreativeRotationMethodMapping.Scheduled;

                if (sessionDraft_next.start && sessionDraft_next.end) {
                    // If we already have a start and end date then this means
                    // that we're using multiple flights in the campaign which is
                    // set at the campaign level. We should add a default schedule
                    // for creatives.
                    sessionDraft_next.rotation_rules.scheduled.push({
                        id: uuid.v4(),
                        weighted: [],
                        isManualWeight: false,
                        start: sessionDraft_next.start,
                        end: sessionDraft_next.end,
                    });
                }
            }

            // We should only erase the start and end date if this isn't
            // a multi-flight campaign for the ad we're duplicating.
            if (
                (duplicate || ad.unalteredDuplicate === true) &&
                campaign.flightPacingStrategy === 'ad'
            ) {
                sessionDraft_next.start = '';
                sessionDraft_next.end = '';
            }

            const creativeMapping = {};
            _.each(creatives, creative => {
                creativeMapping[creative.id] = creative;
            });

            if (campaign.fta_partner_id && campaign.fta_partner_id !== ('2121' || '3131')) {
                sessionDraft_next.fta.partner_id = campaign.fta_partner_id;
            }

            const dealsExcludingOtherAdvertisers = excludeDifferentAdvertisers(
                advertiser,
                dealsByOrganization
            );

            const appListExcludingOtherAdvertisers = excludeDifferentAdvertisers(
                advertiser,
                applists
            );
            return {
                ...initialState,
                campaignBillingEnabled: campaign.billing_enabled,
                revenueModel: campaign.revenueModel,
                audiencesMapping,
                audienceSourceTypeRates,
                campaign,
                draft: sessionDraft_next,
                isLoading: false,
                isDirty: false,
                isOpen: true,
                inventoryPredictionLoading: false,
                inventoryPredictionError,
                geoCategories: geoCategories_next,
                validGeoLayers,
                unassignedPoints,
                currentAd,
                isDelivering: rawAd ? rawAd.isDelivering : false,
                isStale: rawAd ? rawAd.isStale : false,
                shouldShowFrequencyCapWarningOnChange: shouldShowFrequencyCapWarningOnChange(
                    adId,
                    sessionDraft_next
                ),
                ownOrganization,
                isPoiRecategorized: rawAd ? rawAd.poi_recategorized : false,
                stats,
                mode,
                creatives,
                visiblePanel,
                applists: appListExcludingOtherAdvertisers,
                techFee,
                dealsByOrganization: dealsExcludingOtherAdvertisers,
                ftaLocationLists,
                campaignBudgetAllocationMethod,
                campaignCarriersISPsVersion,
                conversions,
                isCrossPlatformCampaign,
                flexSegmentMapping,
                creativeMapping,
                advertiser,
            };
        },
    },
    setBillingTermDefault(),
    {
        description: 'Handle initialize failure',
        target(state, action) {
            return 'AD_FORM__INIT_FAIL' === action.type;
        },
        reduce(state, action) {
            const httpErrors = _.map(action.error, error => ({
                field: error.field,
                message: error.message,
            }));
            return {
                ...state,
                errors: httpErrors,
            };
        },
    },
    {
        description: 'Handle draft changes',
        target(state, action) {
            return 'AD_FORM__UPDATE' === action.type;
        },
        reduce(state, action) {
            const { formData } = action.payload;

            let scheduledRotationWarningEnabled = false;
            if (
                state.draft.rotation_rules.mode === CreativeRotationMethodMapping.Scheduled &&
                (formData.start || formData.end)
            ) {
                formData.rotation_rules = syncScheduleRotationWithFlight({
                    ad: state.draft,
                    changes: formData,
                });
                scheduledRotationWarningEnabled = true;
            }

            let nextDraft = {
                ...state.draft,
                ...formData,
            };

            nextDraft = updateBudgetFields(nextDraft, state.campaign, formData);

            return {
                ...state,
                draft: nextDraft,
                isDirty: true,
                scheduledRotationWarningEnabled,
            };
        },
    },
    platformChangeHandler(),
    {
        description: 'Handle Platform Changes',
        target(state, action) {
            return 'AD_FORM__PLATFORMS__CHANGE' === action.type;
        },
        reduce(state, action) {
            const { value, checked } = action.payload;
            let newPlatforms = state.draft.platforms;

            if (checked) {
                newPlatforms = _.concat(newPlatforms, value);
            } else {
                newPlatforms = _.filter(newPlatforms, platform => platform !== value);
            }

            // Update the exchanges_blocked field, which is based on the selected platforms
            const exchangesBlocked = formatExchangesBlocked(
                state.draft.exchanges_blocked_ui,
                newPlatforms
            );

            return {
                ...state,
                draft: {
                    ...state.draft,
                    platforms: newPlatforms,
                    exchanges_blocked: exchangesBlocked,
                },
            };
        },
    },
    {
        description: 'Handle module draft changes',
        target(state, action) {
            return 'AD_FORM__MODULE__UPDATE' === action.type;
        },
        reduce(state, action) {
            if (!state.draft) {
                return state;
            }

            if (!isTacticsEnabled(state.draft.tactics_enabled, state.campaign.id)) {
                return state;
            }

            const { draft, errorKey, errors } = action.payload;

            let moduleErrors = { ...state.moduleErrors };

            if (errorKey) {
                moduleErrors[errorKey] = !!errors;
            }

            return {
                ...state,
                draft: {
                    ...state.draft,
                    ...draft,
                },
                moduleErrors,
            };
        },
    },
    {
        description: 'Handle Audience changes',
        target(state, action) {
            return 'AD_FORM__AUDIENCES__UPDATE' === action.type;
        },
        reduce(state, action) {
            const { includedItems, excludedItems, geo_targeting_settings } = action.payload;
            const { shouldHideFlexSegments } = state.campaign;

            const audienceFee = calculateAudienceFee({
                includedAudiences: includedItems,
                excludedAudiences: excludedItems,
                audienceRates: state.ownOrganization.audience_rates,
            });

            const nextDraft = !shouldHideFlexSegments
                ? {
                      ...state.draft,
                      audience_fee: audienceFee,
                      audiences: _.map(includedItems, item => item.value),
                      audience_exclude: _.map(excludedItems, item => item.value),
                      geo_targeting_settings,
                  }
                : {
                      ...state.draft,
                      audience_fee: audienceFee,
                      audiences: _.map(includedItems, item => item.value),
                      audience_exclude: _.map(excludedItems, item => item.value),
                  };

            return {
                ...state,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Show errors',
        target(state, action) {
            return 'AD_FORM__SHOW_ERRORS' === action.type;
        },
        reduce(state) {
            return {
                ...state,
                showErrors: true,
                isSubmitting: false,
                isLoading: false,
            };
        },
    },
    {
        description: 'Handle form submit',
        target(state, action) {
            return 'AD_FORM__SUBMIT' === action.type;
        },
        reduce(state) {
            return {
                ...state,
                isSubmitting: true,
            };
        },
    },
    {
        description: 'Handle Ad Preset modal open',
        target(state, action) {
            return 'AD_FORM_OPEN_AD_PRESET_MODAL' === action.type;
        },
        reduce(state) {
            return {
                ...state,
                isAdPresetModalOpen: true,
            };
        },
    },
    {
        description: 'Handle Ad Preset modal close',
        target(state, action) {
            return 'AD_FORM_CLOSE_AD_PRESET_MODAL' === action.type;
        },
        reduce(state) {
            return {
                ...state,
                isAdPresetModalOpen: false,
            };
        },
    },
    {
        description: 'Handle set Ad Preset',
        target(state, action) {
            return 'AD_FORM_SET_AD_PRESET' === action.type;
        },
        reduce(state, action) {
            const { adpreset } = action.payload;
            const { platforms } = state.draft;

            const enabledFields = _.chain(adpreset.ad_preset_properties)
                .reduce((result, item) => {
                    _.each(
                        _.filter(item.properties, pr => pr.checked === true),
                        prop => {
                            if (prop.key === 'audiences') {
                                result.push('geo_targeting_settings');
                            } else if (prop.fields && prop.fields.length > 0) {
                                result.push(...prop.fields);
                            } else {
                                result.push(prop.key);
                            }
                        },
                        []
                    );
                    return result;
                }, [])
                .filter(field => canAdPresetFieldBeUsed(platforms, field))
                .value();

            const newDataFromAdPreset = getAdPresetValuesToApply(
                platforms,
                adpreset,
                enabledFields
            );

            return {
                ...state,
                draft: {
                    ...state.draft,
                    ...newDataFromAdPreset,
                },
                isAdPresetModalOpen: false,
                adPresetKeyCounter: Date.now(),
            };
        },
    },
    {
        description: 'Handle form submit fail',
        target(state, action) {
            return 'AD_FORM_SUBMIT_SERVER_ERROR__SHOW' === action.type;
        },
        reduce(state, action) {
            const httpErrors = _.map(action.error, error => ({
                field: error.field,
                message: error.message,
            }));

            const httpErrorsObject = {};
            _.each(action.error, error => {
                httpErrorsObject[error.field] = error.message;
            });

            return {
                ...state,
                errors: httpErrors,
                errorMapping: httpErrorsObject,
                showErrors: true,
                isLoading: false,
                isSubmitting: false,
                isDraftValid: false,
            };
        },
    },
    {
        description: 'Handle form submit success',
        target(state, action) {
            return 'AD_FORM__SUBMIT_SUCCESS' === action.type;
        },
        reduce() {
            return {
                ...initialState,
                isSubmitting: false,
            };
        },
    },
    {
        description: 'Handle start ASAP',
        target(state, action) {
            return 'AD_FORM__SET_START_ASAP' === action.type;
        },
        reduce(state) {
            const startNow = !state.startNow;
            return {
                ...state,
                startNow,
            };
        },
    },
    {
        description: 'Handle form close',
        target(state, action) {
            return 'AD_FORM__CLOSE' === action.type;
        },
        reduce() {
            return {
                ...initialState,
                unassignedPoints: [],
            };
        },
    },
    {
        description: 'Handle add geo point',
        target(state, action) {
            return 'AD_FORM__ADD_GEO_POINT' === action.type;
        },
        reduce(state, action) {
            const { unassignedPoints } = state;
            const { point } = action.payload;

            const newPoints = [...unassignedPoints, point];

            return {
                ...state,
                unassignedPoints: newPoints,
            };
        },
    },
    {
        description: 'Handle remove geo point',
        target(state, action) {
            return 'AD_FORM__REMOVE_GEO_POINT' === action.type;
        },
        reduce(state, action) {
            const { unassignedPoints } = state;
            const { tempPointId } = action.payload;

            const newPoints = _.filter(unassignedPoints, point => point.pointId !== tempPointId);

            return {
                ...state,
                unassignedPoints: newPoints,
            };
        },
    },
    {
        description: 'Handle geo layers post success',
        target(state, action) {
            return 'SYSTEM__GEO_LAYERS__POST__SUCCESS' === action.type;
        },
        reduce(state) {
            return {
                ...state,
                unassignedPoints: [],
            };
        },
    },
    {
        description: 'Handle geolayer search',
        target(state, action) {
            return 'AD_FORM__GEOLAYER_SEARCH_STRING_CHANGED' === action.type;
        },
        reduce(state, action) {
            const { searchString } = action.payload;
            const { geoCategories } = action.payload;

            return {
                ...state,
                searchString,
                geoCategories,
            };
        },
    },
    {
        description: 'Handle geolayer finder refresh',
        target(state, action) {
            return 'AD_FORM__GEOLAYER_FINDER__REFRESH' === action.type;
        },
        reduce(state, action) {
            const { geoCategories } = action.payload;

            return {
                ...state,
                geoCategories,
            };
        },
    },
    {
        description: 'Handle geolayer finder folder state changed',
        target(state, action) {
            return 'AD_FORM__GEOLAYER_FINDER__FOLDER_STATE_CHANGED' === action.type;
        },
        reduce(state, action) {
            const { geoCategories } = action.payload;

            return {
                ...state,
                geoCategories,
            };
        },
    },
    {
        description: 'Handle creatives mode switch',
        target(state, action) {
            return 'AD_FORM__CREATIVES__SWITCH_MODE' === action.type;
        },
        reduce(state, action) {
            const { newMode, selectedCreatives } = action.payload;

            const {
                nextWeighted,
                nextCreatives,
                nextScheduled,
                nextWeather,
            } = handleCreativesModeSwitch(state.draft, newMode, selectedCreatives);
            const nextDraft = {
                ...state.draft,
                creative: nextCreatives || state.draft.creative,
                rotation_rules: {
                    mode: newMode,
                    weighted: nextWeighted,
                    scheduled: nextScheduled || state.draft.rotation_rules.scheduled,
                    weather: nextWeather,
                },
            };

            return {
                ...state,
                scheduledRotationWarningEnabled: false,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle creatives weight change',
        target(state, action) {
            return 'AD_FORM__CREATIVES__CHANGE_WEIGHT' === action.type;
        },
        reduce(state, action) {
            const { weighted } = state.draft.rotation_rules;
            const { creativeId, newWeight } = action.payload;

            const nextWeighted = changeWeight(weighted, creativeId, newWeight);
            const nextDraft = {
                ...state.draft,
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    mode: CreativeRotationMethodMapping.Weighted,
                    weighted: nextWeighted,
                },
            };

            return {
                ...state,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle creatives clear',
        target(state, action) {
            return 'AD_FORM__CREATIVES__CLEAR_CREATIVES' === action.type;
        },
        reduce(state) {
            const { nextCreatives, nextRotationRules } = handleClearCreatives(state.draft);

            const nextDraft = {
                ...state.draft,
                creative: nextCreatives,
                rotation_rules: nextRotationRules,
            };

            return {
                ...state,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle select single creative',
        target(state, action) {
            return 'AD_FORM__CREATIVES__SELECT_SINGLE_CREATIVE' === action.type;
        },
        reduce(state, action) {
            let { creativeId } = action.payload;

            const nextDraft = {
                ...state.draft,
                creative: [creativeId],
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    weighted: [],
                },
            };

            return {
                ...state,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle select even creative',
        target(state, action) {
            return 'AD_FORM__CREATIVES__SELECT_EVEN_CREATIVE' === action.type;
        },
        reduce(state, action) {
            const { nextWeighted } = handleSelectEvenCreative(action.payload.creativeIds);
            const nextCreatives = action.payload.creativeIds;
            const nextDraft = {
                ...state.draft,
                creative: nextCreatives,
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    weighted: nextWeighted,
                },
            };

            return {
                ...state,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle select weighted creative',
        target(state, action) {
            return 'AD_FORM__CREATIVES__SELECT_WEIGHTED_CREATIVE' === action.type;
        },
        reduce(state, action) {
            const nextCreatives = action.payload.creativeIds;
            const { weighted } = state.draft.rotation_rules;

            const { nextWeighted } = handleSelectWeightedCreative(nextCreatives, weighted);

            const nextDraft = {
                ...state.draft,
                creative: nextCreatives,
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    weighted: nextWeighted,
                },
            };

            return {
                ...state,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle select multi creative',
        target(state, action) {
            return 'AD_FORM__CREATIVES__SELECT_MULTI_CREATIVE' === action.type;
        },
        reduce(state, action) {
            const { nextWeighted } = handleSelectEvenCreative(action.payload.creativeIds);
            const nextCreatives = action.payload.creativeIds;
            const nextDraft = {
                ...state.draft,
                creative: nextCreatives,
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    weighted: nextWeighted,
                },
            };

            return {
                ...state,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle add scheduled creative row',
        target(state, action) {
            return 'AD_FORM__CREATIVES__ADD_CREATIVE_SCHEDULE_ROW' === action.type;
        },
        reduce(state) {
            const nextScheduled = handleAddCreativeScheduleRow(state.draft);

            const nextDraft = {
                ...state.draft,
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            };

            return {
                ...state,
                scheduledRotationWarningEnabled: false,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle remove date range in scheduled weighted creative rotation',
        target(state, action) {
            return (
                'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__REMOVE_DATE_RANGE' === action.type
            );
        },
        reduce(state, action) {
            const { dateRangeIndex } = action.payload;

            const { nextScheduled } = removeScheduledWeightedCreativeDateRange({
                draft: state.draft,
                dateRangeIndex,
            });

            const nextDraft = {
                ...state.draft,
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            };

            return {
                ...state,
                scheduledRotationWarningEnabled: false,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle add date range in scheduled weighted creative rotation',
        target(state, action) {
            return (
                'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__ADD_DATE_RANGE' === action.type
            );
        },
        reduce(state) {
            const { nextScheduled } = addScheduledWeightedCreativeDateRange({
                draft: state.draft,
            });

            const nextDraft = {
                ...state.draft,
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            };

            return {
                ...state,
                scheduledRotationWarningEnabled: false,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle Remove Schedule Creatives',
        target(state, action) {
            return (
                'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__REMOVE_CREATIVES' === action.type
            );
        },
        reduce(state, action) {
            const { dateRangeIndex } = action.payload;
            const { nextScheduled } = handleMultiClearCreatives({
                draft: state.draft,
                dateRangeIndex,
            });

            const nextDraft = {
                ...state.draft,
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            };

            return {
                ...state,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle change end date in scheduled weighted creative rotation',
        target(state, action) {
            return (
                'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__CHANGE_END_DATE' === action.type
            );
        },
        reduce(state, action) {
            const { dateRangeIndex, end } = action.payload;

            const { nextScheduled } = changeScheduledWeightedCreativeEndDate({
                draft: state.draft,
                dateRangeIndex,
                end,
            });

            const nextDraft = {
                ...state.draft,
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            };

            return {
                ...state,
                scheduledRotationWarningEnabled: false,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle creative removal in scheduled weighted creative rotation',
        target(state, action) {
            return (
                'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__REMOVE_CREATIVE' === action.type
            );
        },
        reduce(state, action) {
            const { dateRangeIndex, creativeIndex } = action.payload;

            const { nextScheduled } = removeScheduledWeightedCreative({
                draft: state.draft,
                dateRangeIndex,
                creativeIndex,
            });

            const nextDraft = {
                ...state.draft,
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            };

            return {
                ...state,
                scheduledRotationWarningEnabled: false,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle add creative in scheduled weighted rotation',
        target(state, action) {
            return 'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__ADD_CREATIVE' === action.type;
        },
        reduce(state, action) {
            const { dateRangeIndex } = action.payload;

            const { nextScheduled } = addCreativeToScheduledWeightedCreative({
                draft: state.draft,
                dateRangeIndex,
            });

            const nextDraft = {
                ...state.draft,
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            };

            return {
                ...state,
                scheduledRotationWarningEnabled: false,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle weight change of scheduled weighted creative rotation',
        target(state, action) {
            return 'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__CHANGE_WEIGHT' === action.type;
        },
        reduce(state, action) {
            const { dateRangeIndex, creativeIndex, weight } = action.payload;

            const { nextScheduled } = changeScheduledWeightedCreativeWeight({
                draft: state.draft,
                dateRangeIndex,
                creativeIndex,
                weight,
            });

            const nextDraft = {
                ...state.draft,
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            };

            return {
                ...state,
                scheduledRotationWarningEnabled: false,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle weight change of scheduled weighted evenly',
        target(state, action) {
            return (
                'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__EVEN_CHANGE_WEIGHT' ===
                action.type
            );
        },
        reduce(state, action) {
            const { dateRangeIndex } = action.payload;

            const { nextScheduled } = changeScheduledEvenWeighted({
                draft: state.draft,
                dateRangeIndex,
            });

            const nextDraft = {
                ...state.draft,
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            };

            return {
                ...state,
                scheduledRotationWarningEnabled: false,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle selection of scheduled weighted creative',
        target(state, action) {
            return 'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__SELECT' === action.type;
        },
        reduce(state, action) {
            const { dateRangeIndex, creativeIndex, creativeId } = action.payload;

            const { nextScheduled } = changeScheduledWeightedCreativeSelection({
                draft: state.draft,
                dateRangeIndex,
                creativeIndex,
                creativeId,
            });

            const nextDraft = {
                ...state.draft,
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            };

            return {
                ...state,
                scheduledRotationWarningEnabled: false,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle update scheduled creative row',
        target(state, action) {
            return 'AD_FORM__CREATIVES__UPDATE_CREATIVE_SCHEDULE_ROW' === action.type;
        },
        reduce(state, action) {
            const { rowId, data } = action.payload;

            const { nextScheduled } = handleUpdateCreativeScheduleRow(state.draft, rowId, data);
            const nextDraft = {
                ...state.draft,
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            };

            return {
                ...state,
                scheduledRotationWarningEnabled: false,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle remove scheduled creative row',
        target(state, action) {
            return 'AD_FORM__CREATIVES__REMOVE_CREATIVE_SCHEDULE_ROW' === action.type;
        },
        reduce(state, action) {
            const { rowId } = action.payload;
            const nextScheduled = handleRemoveCreativeScheduleRow(rowId, state.draft);

            const nextDraft = {
                ...state.draft,
                rotation_rules: {
                    ...state.draft.rotation_rules,
                    weighted: [],
                    scheduled: nextScheduled,
                },
            };

            return {
                ...state,
                scheduledRotationWarningEnabled: false,
                draft: nextDraft,
            };
        },
    },
    {
        description: 'Handle submit success of new creative',
        target(state, action) {
            return 'OVERVIEW__CREATIVE_DRAFT_NEW__SUBMIT_SUCCESS' === action.type;
        },
        reduce(state, action) {
            if (!state.isOpen) {
                return state;
            }

            const creativeId = _.get(action.payload, 'creative.id');

            if (state.draft.rotation_rules.mode === CreativeRotationMethodMapping.Single) {
                return {
                    ...state,
                    draft: {
                        ...state.draft,
                        creative: [creativeId],
                    },
                };
            }

            return {
                scheduledRotationWarningEnabled: false,
                ...state,
            };
        },
    },
    {
        description: 'Handle generate prediction',
        target(state, action) {
            return 'AD_FORM__GENERATE_PREDICTION' === action.type;
        },
        reduce(state) {
            return {
                ...state,
                inventoryPredictionLoading: true,
                inventoryPredictionError: '',
                draft: {
                    ...state.draft,
                    predicted_daily_impressions: 0,
                    predicted_daily_uniques: 0,
                    predicted_total_impressions_max: 0,
                    predicted_total_impressions_min: 0,
                    predicted_total_uniques_max: 0,
                    predicted_total_uniques_min: 0,
                },
            };
        },
    },
    {
        description: 'Handle',
        target(state, action) {
            return 'AD_FORM__GENERATE_PREDICTION__COMPLETE_EF_51' === action.type;
        },
        reduce(state, action) {
            return {
                ...state,
                inventoryPredictionLoading: false,
                draft: {
                    ...state.draft,
                    predicted_daily_impressions: action.payload.dailyAvailableImpressions,
                    predicted_daily_uniques: action.payload.dailyUniqueUsers,

                    predicted_total_impressions_min: action.payload.totalAvailableImpressionsMin,
                    predicted_total_uniques_min: action.payload.totalUniqueUsersMin,

                    predicted_total_impressions_max: action.payload.totalAvailableImpressionsMax,
                    predicted_total_uniques_max: action.payload.totalUniqueUsersMax,
                },
            };
        },
    },
    {
        description: 'Handle inventory prediction complete',
        target(state, action) {
            return 'AD_FORM__GENERATE_PREDICTION__COMPLETE' === action.type;
        },
        reduce(state, action) {
            return {
                ...state,
                inventoryPredictionLoading: false,
                draft: {
                    ...state.draft,
                    predicted_daily_impressions: action.payload.dailyAvailableImpressions,
                    predicted_daily_uniques: action.payload.dailyUniqueUsers,
                },
            };
        },
    },
    {
        description: 'Handle prediction fail',
        target(state, action) {
            return 'AD_FORM__GENERATE_PREDICTION__FAILED' === action.type;
        },
        reduce(state, action) {
            return {
                ...state,
                inventoryPredictionLoading: false,
                inventoryPredictionError: action.payload.error,
                draft: {
                    ...state.draft,
                    predicted_daily_impressions: null,
                    predicted_daily_uniques: null,
                },
            };
        },
    },
    {
        description: 'Handle frequency cap warning',
        target(state, action) {
            return 'AD_FORM__FREQUENCY_CAP__UPDATE_WARNING' === action.type;
        },
        reduce(state) {
            return {
                ...state,
                showFrequencyCapWarning: updateFrequencyCapWarning(state),
            };
        },
    },
    {
        description: 'Handle fta line update',
        target(state, action) {
            return 'AD_FORM__FTA__UPDATE_LINE_ID' === action.type;
        },
        reduce(state, action) {
            return updateFtaDraft(state, {
                line_id: action.payload.lineId,
            });
        },
    },
    {
        description: 'Handle fta partner update',
        target(state, action) {
            return 'AD_FORM__FTA__UPDATE_PARTNER_ID' === action.type;
        },
        reduce(state, action) {
            return updateFtaDraft(state, {
                partner_id: action.payload.partnerId,
            });
        },
    },
    {
        description: 'Append newly created Creative to state',
        target(state, action) {
            return 'CREATIVE__CREATED' === action.type && state.creatives;
        },
        reduce(state, action) {
            const { creative } = action.payload;

            return {
                ...state,
                creatives: state.creatives.concat({
                    id: creative.id,
                    name: creative.name,
                    format: creative.format,
                }),
                creativeMapping: {
                    ...state.creativeMapping,
                    [creative.id]: {
                        format: creative.format,
                        id: creative.id,
                        platforms: creative.platforms,
                    },
                },
            };
        },
    },
    handleChangeIasPublicisViewability(),
    handleChangeIasGroupmViewability(),
    handleChangeIASContextualTargeting(),
    handleChangeIASFraudPreventionRisk(),
    handleChangeIASFraudPreventionUnrateable(),
    handleChangeIASFraudPreventionIp(),
    handleIASViewabilityChange(),
    handleIASBrandSafetyChange(),
    // Derive fields
    {
        description: 'Determine if selected Creative format is supported by IAS',
        target(state, action) {
            return /^AD_FORM__/.test(action.type) && state.draft;
        },
        reduce(state) {
            const creative = _.find(state.creatives, { id: state.draft.creative[0] });

            if (!creative) {
                return state;
            }

            const isUsingIASSupportedCreativeFormat = _.includes(
                ['standard', 'custom_html', 'video', 'third_party_vast', 'mraid'],
                creative.format
            );

            return {
                ...state,
                isUsingIASSupportedCreativeFormat,
            };
        },
    },
    deriveIASFraudPrevention(),
    deriveAudienceFees(),
    deriveIASBrandSafety(),
    deriveIASContextualTargeting(),
    deriveIASPostBidVerification(),
    {
        description: 'Validate with validators',
        target(state, action) {
            return /^AD_FORM__/.test(action.type);
        },
        reduce(state) {
            if (!state.campaign) {
                return state;
            }
            const errors = validateAdDraft({
                draft: state.draft,
                originalAd: state.currentAd,
                billingEnabled: state.campaignBillingEnabled,
                thirdPartyFees: calculateThirdPartyFees(state.campaign),
                orgFtaPartnerId: state.ownOrganization.fta_partner_id,
                validGeoLayers: state.validGeoLayers,
                revenueModel: state.revenueModel,
                stats: state.stats,
                campaignBudgetAllocationMethod: state.campaignBudgetAllocationMethod,
                techFee: state.campaign.tech_fee,
                campaignFtaVersion: state.campaign.fta_version,
                campaignType: state.campaign.type,
            });

            const errorMapping = {};

            _.each(errors, error => {
                errorMapping[error.field] = error.message;
            });

            return {
                ...state,
                errors,
                errorMapping,
                isDraftValid: errors.length === 0,
            };
        },
    },
    {
        description: 'Validate end date',
        target(state, action) {
            return /^AD_FORM__/.test(action.type) && state.draft;
        },
        reduce(state) {
            const errorMapping = { ...state.errorMapping };
            const { currentAd } = state;
            const { start, end } = state.draft;
            const now = moment.utc();

            if (start && end && moment.utc(start).isAfter(end)) {
                errorMapping.end = 'End date must come after Start date';
            }

            // If this is a new Ad, ignore validation
            if (!currentAd) {
                return state;
            }

            // If the End date didn't change, ignore validation
            if (currentAd && currentAd.end === end) {
                return state;
            }

            if (currentAd && currentAd.isDelivering && moment.utc(end).isBefore(now)) {
                errorMapping.end = 'End date can only be extended beyond now';
            }

            return { ...state, errorMapping };
        },
    },
    {
        description: 'Validate with JOI schema',
        target(state, action) {
            return /^AD_FORM__/.test(action.type);
        },
        reduce(state) {
            const options = {
                allowUnknown: true,
                abortEarly: false,
                context: {
                    draft: state.draft,
                },
            };

            const result = schema.validate(state.draft, options);

            if (!result.error) {
                return state;
            }

            const errorMapping = { ...state.errorMapping };

            _.each(result.error.details, detail => {
                errorMapping[detail.path.join('.')] = detail.message;
            });

            return {
                ...state,
                errorMapping,
            };
        },
    },
    {
        description: 'Add panel',
        target(state, action) {
            return 'AD_FORM__ADD_PANEL' === action.type;
        },
        reduce(state, action) {
            return {
                ...state,
                visiblePanel: {
                    ...state.visiblePanel,
                    [action.payload.panel]: !state.visiblePanel[action.payload.panel],
                },
            };
        },
    },
    {
        description: 'Update Ad Form Hard Setup Warnings',
        target(state, action) {
            return /^AD_FORM__/.test(action.type) && state.draft;
        },
        reduce(state) {
            const audienceMapping = _.get(state, 'audiencesMapping');
            const flexSegmentMapping = _.get(state, 'flexSegmentMapping');
            const creativeMapping = _.get(state, 'creativeMapping');
            const restrictedCategory = _.get(state, 'campaign.restricted_category');
            const generalErrors = _.get(state, 'errorMapping');
            const campaignType = _.get(state, 'campaign.type');

            const adDraft = _.get(state, 'draft', {});
            const tactics = _.get(adDraft, 'tactics_generators');
            const adPlatforms = _.get(adDraft, 'platforms');
            const geotargets = _.get(adDraft, 'geotargets');

            const tacticWarnings = getTacticWarnings({
                tactics,
                audienceMapping,
                adPlatforms,
                flexSegmentMapping,
                geotargets,
                restrictedCategory,
                generalErrors,
                isCTVCampaign: campaignType === CampaignTypeMapping.CTV,
                isDOOHCampaign: campaignType === CampaignTypeMapping.DOOH,
                orgId: state.ownOrganization.id,
            });
            const deviceOsWarning = getDeviceOsWarning({
                selectedDevices: adDraft.target_device_os,
                platforms: adPlatforms,
            });
            const carrierIspWarning = getCarrierISPWarning({
                includedCarriers: adDraft.target_carriers,
                platforms: adPlatforms,
            });

            const creativeWarning = processCreativeWarning({
                creativeIds: adDraft.creative,
                rotation_rules: adDraft.rotation_rules,
                creativeMapping,
                adPlatforms,
            });

            const exchangeWarning = getExchangeTargetingWarning({
                platforms: adPlatforms,
                exchangesBlockedUI: adDraft.exchanges_blocked_ui,
                restrictedCategory,
                campaignType,
            });

            return {
                ...state,
                warnings: {
                    tactics: tacticWarnings,
                    device_os: deviceOsWarning,
                    carrier_isp: carrierIspWarning,
                    creative: creativeWarning,
                    exchange: exchangeWarning,
                },
            };
        },
    },
    {
        target(state, action) {
            return 'AD_FORM_SUBMODULE__DRAFT_UPDATE' === action.type;
        },
        reduce(state, action) {
            const { draft, errors } = action.payload;

            const newDraft = {
                ...state.draft,
                ...draft,
            };

            return { ...state, draft: newDraft, errorMapping: errors };
        },
    },
    {
        target(state, action) {
            return 'AD_FORM__OPEN_WARNING_CONFIRMATION_MODAL' === action.type;
        },
        reduce(state) {
            return {
                ...state,
                isWarningModalOpen: true,
            };
        },
    },
    {
        target(state, action) {
            return 'AD_FORM__CANCEL_SAVE' === action.type;
        },
        reduce(state) {
            return {
                ...state,
                isWarningModalOpen: false,
                isSubmitting: false,
            };
        },
    },
];

export const NAME = 'adForm';

export const MAX_TOTAL_CREATIVE_ROTATION_WEIGHT = 100;
export const CREATIVE_ROTATION_WEIGHT_PRECISION = 2;

export default combineReducers({
    form: reducer,
    poiViewer,
});

function deriveAudienceFees() {
    return {
        description: 'Derive audience fees',
        target(state, action) {
            return /^AD_FORM__/.test(action.type) && state.draft;
        },
        reduce(state) {
            const { audienceSourceTypeRates, audiencesMapping } = state;

            let audiences = [];

            if (state.draft.tactics_enabled) {
                _.each(state.draft.tactics_generators, tactic => {
                    _.each(tactic.targeting, target => {
                        if (target.module === 'audience_segments' && target.draft) {
                            audiences = audiences.concat(
                                target.draft.audiences,
                                target.draft.audience_exclude
                            );
                        }
                    });
                });
            } else {
                audiences = audiences.concat(state.draft.audiences, state.draft.audience_exclude);
            }

            const audienceFee = _(audiences)
                .filter(x => x)
                .map(audienceId => {
                    const sourceType = _.get(audiencesMapping, [audienceId, 'source_type']);
                    const rate = _.get(audienceSourceTypeRates, [sourceType], 0);

                    return rate;
                })
                .max();

            // 1. take current third party fee to state and remove all from audience segments
            const thirdPartyFeesFromForm = _.filter(state.draft.thirdPartyFees, {
                disabled: false,
            });

            // 2. get all third party fees from segments
            const newThirdPartyFeesFromSegment = _.map(audiences, audienceId => {
                return _.get(audiencesMapping, [audienceId]);
            })
                .filter(audience => audience && audience.third_party_fees)
                .map(audience => {
                    return {
                        description: audience.name,
                        billing_model: 'cpm',
                        fee: audience.third_party_fees,
                        billable: true,
                        disabled: true,
                    };
                });

            // 3. merge third party from segments and from form with new form
            const thirdPartyFees = _.filter(
                _.concat(newThirdPartyFeesFromSegment, thirdPartyFeesFromForm)
            );

            const draft = {
                ...state.draft,
                audience_fee: audienceFee || 0,
                thirdPartyFees: _.uniqBy(thirdPartyFees, function(e) {
                    if (e) return e.description;
                }),
            };

            const visiblePanel = {
                ...state.visiblePanel,
                thirdPartyFees:
                    state.visiblePanel &&
                    (state.visiblePanel.thirdPartyFees || thirdPartyFees.length) > 0,
            };

            return { ...state, draft, visiblePanel: visiblePanel };
        },
    };
}

export function setBillingTermDefault() {
    return {
        description: 'Set default Billing Terms from Campaign Revenue Model',
        target(state, action) {
            return 'AD_FORM__INIT' === action.type;
        },
        reduce(state, action) {
            let billingTerm;
            if (action.payload.duplicate) {
                // When duplicating, just use the value in the source Ad
                billingTerm = action.payload.ad.ef_billing_terms;
            } else if (state.currentAd && state.currentAd.ef_billing_terms) {
                billingTerm = state.currentAd.ef_billing_terms;
            } else {
                billingTerm = revenueModelToBillingTerm(state.revenueModel);
            }
            const draft = {
                ...state.draft,
                ef_billing_terms: billingTerm,
            };

            return { ...state, draft };
        },
    };
}

export function reducerWithMiddleware(state = {}, action) {
    if (!_.isString(action.type)) {
        return state;
    }

    let nextState = state;
    _.each(middleware, mw => {
        if (mw.target(nextState, action, state)) {
            nextState = mw.reduce(nextState, action, state);
        }
    });

    return nextState;
}

export function reducer(state, action) {
    let nextState = reducerWithMiddleware(state, action);
    nextState = reducerV2(nextState, action);

    return nextState;
}

function updateFtaDraft(state, changes) {
    const nextState = {
        ...state,
        draft: {
            ...state.draft,
            fta: {
                ...state.draft.fta,
                ...changes,
            },
        },
    };

    const errors = validateAdDraft({
        draft: nextState.draft,
        originalAd: state.currentAd,
        billingEnabled: state.campaign.billing_enabled,
        thirdPartyFees: calculateThirdPartyFees(state.campaign),
        orgFtaPartnerId: state.ownOrganization.fta_partner_id,
        validGeoLayers: state.validGeoLayers,
        revenueModel: state.campaign.revenueModel,
        stats: state.stats,
        techFee: state.campaign.tech_fee,
        campaignFtaVersion: state.campaign.fta_version,
        campaignType: state.campaign.type,
    });

    return {
        ...nextState,
        errors,
    };
}

function getValidGeoLayers(geoLayers) {
    const mapping = {};
    _.each(geoLayers, layer => {
        mapping[layer.id] = layer;
    });

    return mapping;
}

function deriveIASPostBidVerification() {
    return {
        description: 'Derive IAS Post Bid Verification',
        target(state, action) {
            return /^AD_FORM__/.test(action.type) && state.draft;
        },
        reduce(state) {
            const { draft } = state;

            if (isAdUsingDisplayCreative(draft, state.creatives)) {
                return state;
            }

            return {
                ...state,
                draft: {
                    ...state.draft,
                    isIasPostBidVerificationEnabled: null,
                },
            };
        },
    };
}

function excludeDifferentAdvertisers(advertiser, options) {
    let finalOptions = options;
    if (advertiser) {
        finalOptions = _.filter(options, option => {
            return !option.advertiser || option.advertiser.id === advertiser.id;
        });
    }
    return finalOptions;
}
