import _ from 'lodash';
import React from 'react';
/** import third-party libraries in the section above, keep the ascending order */

import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
/** import mui components in the section above, keep the ascending order */

import { formatExchangesBlocked } from 'states/resources/ads/business-logic';
import { getExchangesBlocked } from 'containers/ad-summary';
import { getExchangeTargetingWarning } from 'forms/ad-form/modules/cross-platform-setup-warning/helpers';
import { TargetExchangesBlockedUI } from 'forms/ad-form/modules/target-exchanges-blocked-ui';
import BaseColumnForm from '../base-column-form';
import shallowCompare from 'utils/shallow-compare';
/** import classes and others in the section above, keep the ascending order */

const InvetoryIconContainer = withStyles(() => ({
    root: {
        paddingRight: '9px',
        flexBasis: '4.33%',
    },
}))(Grid);
const TitleContainer = withStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1),
    },
}))(Grid);

export class ExchangesSummary extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate =
            !shallowCompare(this.props, nextProps) || !shallowCompare(this.state, nextState);
        return shouldUpdate;
    }

    render() {
        const { ad } = this.props;

        return (
            <div>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <TitleContainer container alignItems="center">
                            <InvetoryIconContainer item>
                                <CheckIcon fontSize="small" color="primary" />
                            </InvetoryIconContainer>
                            <Grid item>Included Exchanges</Grid>
                        </TitleContainer>
                        <Grid container alignItems="center" wrap="nowrap">
                            <InvetoryIconContainer />
                            <Grid item>Included: All compatible and new exchanges</Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TitleContainer container alignItems="center">
                            <InvetoryIconContainer item>
                                <BlockIcon fontSize="small" color="secondary" />
                            </InvetoryIconContainer>
                            <Grid item>Blocked Exchanges</Grid>
                        </TitleContainer>
                        <Grid container alignItems="center" wrap="nowrap">
                            <InvetoryIconContainer item />
                            <Grid item>{getExchangesBlocked(ad.exchanges_blocked_ui)}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export const ExchangesForm = props => {
    const { ad, ads, campaign, onSave, onCancel } = props;

    const adPlatforms = ad
        ? _.get(ad, 'platforms')
        : _(ads)
              .map(ad => ad.platforms)
              .flatten()
              .uniq()
              .value();

    return (
        <BaseColumnForm
            ad={ad}
            ads={ads}
            campaign={campaign}
            headline="Exchanges"
            fields={['exchanges_blocked_ui', 'exchanges_blocked']}
            shouldRefreshPredictionsOnSave={true}
            onSave={onSave}
            onCancel={onCancel}
            columnName="Exchanges"
        >
            {(updateDraft, shouldShowErrors, errors, draft) => {
                let warningData = {};
                if (campaign.isCrossPlatformCampaign) {
                    warningData = getExchangeTargetingWarning({
                        platforms: adPlatforms,
                        exchangesBlockedUI: draft.exchanges_blocked_ui,
                        restrictedCategory: campaign.restricted_category,
                        campaignType: campaign.type,
                    });
                }

                return (
                    <TargetExchangesBlockedUI
                        onFieldChange={val => {
                            updateDraft({
                                exchanges_blocked_ui: val,
                                exchanges_blocked: formatExchangesBlocked(val, adPlatforms),
                            });
                        }}
                        campaignType={campaign.type}
                        exchangesExcludeValue={draft.exchanges_blocked_ui}
                        isCrossPlatformCampaign={campaign.isCrossPlatformCampaign}
                        exchangeWarning={warningData}
                        errors={errors}
                    />
                );
            }}
        </BaseColumnForm>
    );
};
