import Moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';
import { EXCHANGE_LABEL_MAPPING } from 'forms/ad-form/constants';

import { getEnvironmentSettings } from 'services/environment';

export function formatDate_long(dateOrMoment, timezone = 'UTC') {
    if (null === dateOrMoment) {
        return;
    }
    const moment = dateOrMoment._isAMomentObject ? dateOrMoment : Moment(dateOrMoment);

    return moment.tz(timezone).format('MMMM D, YYYY HH:mm z');
}

export function formatDate_short(dateOrMoment, timezone = 'UTC') {
    if (null === dateOrMoment) {
        return;
    }
    const moment = dateOrMoment._isAMomentObject ? dateOrMoment : Moment(dateOrMoment);

    return moment.tz(timezone).format('MMM D, HH:mm z');
}

export function formatDate_date(dateOrMoment, timezone = 'UTC') {
    if (null === dateOrMoment) {
        return;
    }
    const moment = dateOrMoment._isAMomentObject ? dateOrMoment : Moment(dateOrMoment);

    return moment.tz(timezone).format('MMM D');
}

export function formatDate_dateWithYear(dateOrMoment, timezone = 'UTC') {
    if (null === dateOrMoment) {
        return;
    }

    const moment = dateOrMoment._isAMomentObject ? dateOrMoment : Moment(dateOrMoment);

    return moment.tz(timezone).format('MMM D, YYYY');
}

export function formatDate_formSelect(value) {
    if (value && value.date === null) {
        return 'Start as soon as possible';
    }
    if (value && !value.date && !value.timezone) {
        return '';
    }

    if (!value.date || !value.timezone) {
        return 'Invalid date';
    }

    return formatDate_long(value.date, value.timezone);
}

export const labelExchange = value => {
    const label = value.split('_')[0];
    return EXCHANGE_LABEL_MAPPING[label] || label;
};

export function getTimezoneAbbrv(timezone = 'UTC') {
    return Moment()
        .tz(timezone)
        .format('z');
}

export function formatNumber_currency(number) {
    const environmentSettings = getEnvironmentSettings();
    const currencySign = environmentSettings.getCurrencySign();
    if (window.isNaN(number)) {
        return currencySign.concat('0.00');
    }

    const currencyString = numeral(number).format('0,0.00');
    return currencySign.concat(currencyString);
}

export function formatNumber_percentage(number) {
    if (window.isNaN(number)) {
        return '0%';
    }
    return numeral(number).format('0,0.00%');
}

export function formatNumber_whole(number) {
    if (window.isNaN(number)) {
        return '0';
    }
    return numeral(number).format('0,0');
}

export function formatNumber_wholeFixed(number, decimalPlaces = 2, format = '0,0') {
    if (window.isNaN(number)) {
        return '0';
    }
    return numeral(number).format(`${format}.${padZeros(decimalPlaces)}`);
}

function padZeros(number) {
    const times = Math.max(number, 0);
    return _.times(times, _.constant(0)).join('');
}

export function formatTimeRangeInDaysHours({ start, end }) {
    const startDate = typeof start === 'string' ? new Date(start) : start;
    const endDate = typeof end === 'string' ? new Date(end) : end;

    const HOUR = 60 * 60 * 1000;
    const DAY = 24 * HOUR;

    const totalTime = endDate.valueOf() - startDate.valueOf();

    const wholeDays = Math.floor(totalTime / DAY);
    const nonWholeDayRange = totalTime - wholeDays * DAY;

    const hours = nonWholeDayRange / HOUR;
    const roundedHours = Math.floor(hours * 10) / 10;

    return `${wholeDays} days, ${roundedHours} hours`;
}
export function fixPrecision(value, precision) {
    return parseFloat(value.toFixed(precision));
}

export function getCurrencySignforCurrency(currency) {
    switch (currency) {
        case 'CAD':
            return '$';

        case 'USD':
            return '$';

        case 'EUR':
            return '€';

        default:
            return '$';
    }
}
