import find from 'lodash/find';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import React from 'react';
/** import third-party libraries in the section above, keep the ascending order */

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
/** import mui components in the section above, keep the ascending order */

import { Label } from 'widgets-v5/typography';
import AccordionItem from 'widgets-v5/expansion-panel-item';
import Spacer from 'widgets-v5/spacer';
/** import widgets-v5 components in the section above, keep the ascending order */

import { GroupedMultiSelectTabs } from 'widgets-v6/select';
import { IncludeExcludeLayout } from 'widgets-v6/layout';
/** import widgets-v6 components in the section above, keep the ascending order */

import { ApplistSelector, ApplistSummary } from '../target-applists';
import { DEAL_CATEGORY_OPTIONS, DealCategory } from 'common/constants/deals';
import {
    DOOH_VENUE_TYPES,
    doohChildToParent,
    doohVenueTranslate,
} from 'common/constants/dooh-venue-types';
import { DoohVenueTypeSummary, TargetDoohVenues } from '../target-dooh-venues';
import {
    formatExchangesBlocked,
    formatSelectedDOOHVenueTypes,
} from 'states/resources/ads/business-logic';
import { formatNumber_currency } from 'utils/formatting';
import { getExchangesBlocked, getApplists } from 'containers/ad-summary';
import { TargetExchangesBlockedUI } from 'forms/ad-form/modules/target-exchanges-blocked-ui';
import { useAdForm } from 'forms/ad-form/v2/hook';
import flags from 'containers/flags/service';
/** import classes and others in the section above, keep the ascending order */

const useStyles = makeStyles(() => ({
    appListError: {
        marginTop: 10,
        marginLeft: 30,
        color: 'red',
    },
}));

export default function AdFormInventory({
    expandPanel,
    expanded,
    formData,
    dealsByOrganization,
    adForm,
    onFieldChange,
    isCrossPlatformCampaign,
    showPlatformRestrictedFields,
    environmentSettings,
    errors,
    showErrors,
    applists,
    campaign,
    organizationName,
    advertiser,
    exchangeWarning,
    isDOOHCampaign,
    doohVenuesWarning,
}) {
    const classes = useStyles();
    const { dealsWithConvertedPrice } = useAdForm();

    const handleExchangeExcludeChange = exchangesBlockedUI => {
        const exchangesBlocked = formatExchangesBlocked(exchangesBlockedUI, formData.platforms);
        onFieldChange('exchanges_blocked_ui')(flatten(exchangesBlockedUI));
        onFieldChange('exchanges_blocked')(exchangesBlocked);
    };

    const setDealApplicationType = e => {
        const dealApplicationType = e.target.checked ? 'deal_augmented' : 'deal_only';
        onFieldChange('dealApplicationType')(dealApplicationType);
    };

    const showPrices = deal => {
        const campainCurrency = campaign.currency;
        const dealCurrency = deal.currency;

        if (campainCurrency !== dealCurrency) {
            return `Price: ${dealCurrency} ${formatNumber_currency(
                deal.price_local
            )} (approx. ${campainCurrency} ${formatNumber_currency(deal.convertedPrice)})`;
        }

        return `Price: ${dealCurrency} ${formatNumber_currency(deal.price_local)}`;
    };

    const getDealsOptions = () => {
        return map(dealsWithConvertedPrice, deal => {
            const isCampaignAdvertiser =
                advertiser && deal.advertiser && advertiser.id === deal.advertiser.id;

            const makeGroupLabel = () => {
                if (
                    deal.category === DealCategory.Curated &&
                    flags.isEnabled('efb_35_curated_deals')
                ) {
                    return null;
                } else {
                    return `List for ${
                        isCampaignAdvertiser ? deal.advertiser.name : organizationName
                    }`;
                }
            };

            return {
                label: deal.app_name,
                key: deal.id,
                value: deal.id,
                description: showPrices(deal),
                group: makeGroupLabel(),
                category: deal.category,
            };
        }).filter(deal => {
            if (flags.isEnabled('efb_35_curated_deals')) {
                return true;
            } else {
                return deal.category === DealCategory.Private;
            }
        });
    };

    const getAppsAndSitesOptions = () => {
        return map(applists, applist => {
            const isCampaignAdvertiser =
                advertiser && applist.advertiser && advertiser.id === applist.advertiser.id;
            return {
                key: applist.id,
                value: applist.id,
                label: applist.name,
                group: `List for ${
                    isCampaignAdvertiser ? applist.advertiser.name : organizationName
                }`,
            };
        });
    };

    /**
     * Handles the change event for DOOH venue type field.
     * @param {string} fieldName - The name of the field being changed.
     * @param {string[]} fieldValue - An array of strings representing the parent-child values.
     */
    const handleDoohVenueTypeChange = (fieldName, fieldValue) => {
        const doohVenueValues = formatSelectedDOOHVenueTypes(fieldValue);
        onFieldChange(fieldName)(doohVenueValues);
    };

    return (
        <React.Fragment>
            <Typography>Inventory</Typography>
            <Spacer />
            <AccordionItem
                onClick={expandPanel('public_inventory')}
                expanded={expanded === 'public_inventory'}
                title="Public Inventory"
                details={
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.dealApplicationType === 'deal_augmented'}
                                    onChange={setDealApplicationType}
                                    value="dealApplicationType"
                                    color="primary"
                                />
                            }
                            label="Bid on open auctions"
                        />
                    </div>
                }
                summary={
                    <div>
                        <Label>
                            {formData.dealApplicationType === 'deal_augmented'
                                ? 'Bid on open auctions'
                                : 'Do not bid on open auctions'}
                        </Label>
                    </div>
                }
            />
            <AccordionItem
                onClick={expandPanel('deals')}
                expanded={expanded === 'deals'}
                title={flags.isEnabled('efb_35_curated_deals') ? 'Deals' : 'Private Deals'}
                error={showErrors && errors.deals}
                details={
                    <div>
                        {' '}
                        <GroupedMultiSelectTabs
                            value={formData.deals}
                            onChange={onFieldChange('deals')}
                            options={getDealsOptions()}
                            placeholder={
                                flags.isEnabled('efb_35_curated_deals')
                                    ? 'Select deals'
                                    : 'Select private deals'
                            }
                            tabs={
                                flags.isEnabled('efb_35_curated_deals') ? DEAL_CATEGORY_OPTIONS : []
                            }
                            defaultTab={DealCategory.Private}
                            tabField="category"
                        />
                    </div>
                }
                summary={
                    formData.deals.length > 0 ? (
                        <Label>
                            {_(formData.deals)
                                .map(dealId =>
                                    find(dealsByOrganization, {
                                        id: dealId,
                                    })
                                )
                                .filter(deal => {
                                    if (!deal) {
                                        return false;
                                    }

                                    // Filter out curated deals if the feature flag is disabled
                                    if (!flags.isEnabled('efb_35_curated_deals')) {
                                        return deal.category === DealCategory.Private;
                                    }

                                    return true;
                                })
                                .map(deal => deal.app_name)
                                .join(', ')}
                        </Label>
                    ) : (
                        <Label placeholder>
                            {flags.isEnabled('efb_35_curated_deals')
                                ? 'Select a deal'
                                : 'Select a private deal'}
                        </Label>
                    )
                }
            />
            {adForm.campaign.isExchangeTargetingEnabled && environmentSettings.canUseExchanges() && (
                <AccordionItem
                    onClick={expandPanel('exchangesBlocked')}
                    expanded={expanded === 'exchangesBlocked'}
                    disabled={isCrossPlatformCampaign ? false : !showPlatformRestrictedFields}
                    title="Exchanges"
                    error={
                        exchangeWarning.hasWarning ||
                        (showErrors && (errors.exchanges_blocked_ui || errors.exchanges_blocked))
                    }
                    details={
                        <TargetExchangesBlockedUI
                            onFieldChange={handleExchangeExcludeChange}
                            campaignType={adForm.campaign.type}
                            exchangesExcludeValue={formData.exchanges_blocked_ui}
                            isCrossPlatformCampaign={isCrossPlatformCampaign}
                            exchangeWarning={exchangeWarning}
                            errors={errors}
                        />
                    }
                    summary={
                        <React.Fragment>
                            <IncludeExcludeLayout type="Include" marginBottom>
                                All compatible and new exchanges
                            </IncludeExcludeLayout>
                            <IncludeExcludeLayout type="Exclude">
                                {getExchangesBlocked(formData.exchanges_blocked_ui)}
                            </IncludeExcludeLayout>
                        </React.Fragment>
                    }
                />
            )}
            <AccordionItem
                onClick={expandPanel('applists')}
                expanded={expanded === 'applists'}
                title={environmentSettings.getAppsAndSitesLabel()}
                error={errors.blacklistedAppsAndSites}
                details={
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <ApplistSelector
                                type="Include"
                                label="Allowed"
                                placeholder={environmentSettings.getAppsAndSitesPlaceholder()}
                                onChange={values =>
                                    onFieldChange('whitelistedAppsAndSites')(values)
                                }
                                values={formData.whitelistedAppsAndSites}
                                options={getAppsAndSitesOptions()}
                            />
                        </Grid>
                        <Grid item>
                            <ApplistSelector
                                type="Exclude"
                                placeholder="None"
                                label="Blocked"
                                onChange={values =>
                                    onFieldChange('blacklistedAppsAndSites')(values)
                                }
                                values={formData.blacklistedAppsAndSites}
                                options={getAppsAndSitesOptions()}
                            />
                        </Grid>
                        {errors.blacklistedAppsAndSites && (
                            <Grid item>
                                <Typography
                                    classes={{
                                        root: classes.appListError,
                                    }}
                                >
                                    {errors.blacklistedAppsAndSites}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                }
                summary={
                    <ApplistSummary
                        allowedList={getApplists(formData.whitelistedAppsAndSites, applists)}
                        blockedList={getApplists(formData.blacklistedAppsAndSites, applists)}
                    />
                }
            />
            {environmentSettings.canUseCTVOrDOOH() &&
                isDOOHCampaign &&
                flags.isEnabled('efb_17_dooh_target_venue') && (
                    <AccordionItem
                        onClick={expandPanel('doohVenue')}
                        expanded={expanded === 'doohVenue'}
                        title="Venue Type"
                        error={
                            formData.showErrors &&
                            (errors.target_dooh_venues || errors.target_dooh_venues_exclude)
                        }
                        details={
                            <TargetDoohVenues
                                onFieldChange={handleDoohVenueTypeChange}
                                options={DOOH_VENUE_TYPES}
                                doohVenuesIncludeValue={doohChildToParent(
                                    formData.target_dooh_venues
                                )}
                                doohVenuesExcludeValue={doohChildToParent(
                                    formData.target_dooh_venues_exclude
                                )}
                                doohVenuesWarning={doohVenuesWarning}
                            />
                        }
                        summary={
                            <DoohVenueTypeSummary
                                doohVenuesIncludeValue={doohVenueTranslate(
                                    formData.target_dooh_venues
                                )}
                                doohVenuesExcludeValue={doohVenueTranslate(
                                    formData.target_dooh_venues_exclude
                                )}
                            />
                        }
                    />
                )}
        </React.Fragment>
    );
}
