import _ from 'lodash';
import { connect } from 'react-redux';
import numeral from 'numeral';
import React from 'react';
/** import third-party libraries in the section above, keep the ascending order */

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
/** import mui components in the section above, keep the ascending order */

import { BlockLoadGroup } from 'widgets-v5/load-group';
import { SingleSelect } from 'widgets-v6/select';
import TextFieldFormatter from 'widgets-v6/text-field-formatter';
/** import widgets components in the section above, keep the ascending order */

import { DEAL_TYPE_OPTIONS } from 'common/constants/deals';
import { EXCHANGE_MAPPING } from 'forms/ad-form/constants';
import { getEnvironmentSettings, getEnv } from 'services/environment';
import actions from './actions';
import flags from 'containers/flags/service';
import selector from './selector';
/** import classes and others in the section above, keep the ascending order */

const useStyles = makeStyles(theme => ({
    dialogPadding: {
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
    dialogTitleRoot: {
        padding: `10px ${theme.spacing(1)} 10px ${theme.spacing(3)}`,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    red: {
        color: '#FF6584',
    },
    helperText: {
        margin: '8px 0',
    },
    dropdownContainer: {
        marginTop: '8px',
        marginBottom: '4px',
    },
    placeholder: {
        color: 'darkgrey',
        fontWeight: 300,
    },
}));

const options = DEAL_TYPE_OPTIONS;

const showCurrencyOptions = environmentSettings => {
    return environmentSettings.getDealCurrencyOptions().map(option => {
        return (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        );
    });
};

const DealModal = ({
    open,
    dispatch,
    isDetailsMode,
    deal,
    loading,
    draft,
    errors,
    showErrors,
    advertiserOptions,
    isCurated,
}) => {
    const classes = useStyles();
    const environmentSettings = getEnvironmentSettings();

    const onClose = () => {
        dispatch(actions.closeInventoryDealsModal());
    };
    const onChange = (field, value) => {
        dispatch(actions.updateField(field, value));
    };

    const fields = [
        {
            label: flags.isEnabled('efb_35_curated_deals') ? 'Deal Name' : 'App/Site Name',
            key: 'app_name',
            required: true,
            getInput: ({ onChange, error, showErrors, classes, draft }) => {
                return (
                    <TextField
                        required
                        fullWidth
                        value={draft.app_name}
                        onChange={e => onChange('app_name', e.target.value)}
                        margin="dense"
                        variant="outlined"
                        placeholder={
                            flags.isEnabled('efb_35_curated_deals')
                                ? 'Enter Deal Name'
                                : 'Enter App/Site Name'
                        }
                        helperText={showErrors && error}
                        error={showErrors && error ? true : false}
                        FormHelperTextProps={{ classes: { root: classes.helperText } }}
                    />
                );
            },
        },
        {
            label: 'Type',
            key: 'type',
            required: true,
            getInput: ({ value, onChange }) => {
                return (
                    <RadioGroup value={value} onChange={e => onChange('type', e.target.value)}>
                        {options.map(option => (
                            <FormControlLabel
                                key={option.label}
                                value={option.value}
                                control={<Radio color="primary" />}
                                label={option.label}
                            />
                        ))}
                    </RadioGroup>
                );
            },
        },
        {
            label: 'Deal ID',
            key: 'id',
            required: true,
            getInput: ({ onChange, showErrors, error, classes, draft, isDetailsMode }) => {
                return (
                    <TextField
                        required
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        value={draft.id}
                        onChange={e => onChange('id', e.target.value)}
                        placeholder={`Enter Deal ID`}
                        helperText={showErrors && error}
                        error={showErrors && error ? true : false}
                        FormHelperTextProps={{ classes: { root: classes.helperText } }}
                        disabled={isDetailsMode ? true : false}
                    />
                );
            },
        },
        {
            label: isCurated ? 'Description' : 'Publisher Name',
            key: 'pub_name',
            required: true,
            getInput: ({ onChange, showErrors, error, classes, draft }) => {
                return (
                    <TextField
                        required
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        value={draft.pub_name}
                        onChange={e => onChange('pub_name', e.target.value)}
                        placeholder={isCurated ? 'Enter Description' : 'Enter Publisher Name'}
                        helperText={showErrors && error}
                        error={showErrors && error ? true : false}
                        FormHelperTextProps={{ classes: { root: classes.helperText } }}
                    />
                );
            },
        },
        {
            label: flags.isEnabled('efb_35_curated_deals') ? 'Exchange' : 'Supply Source',
            key: 'supply_source',
            required: true,
            getInput: ({ onChange, classes, showErrors, error, draft }) => {
                return (
                    <div className={classes.dropdownContainer}>
                        <SingleSelect
                            value={draft.supply_source}
                            options={EXCHANGE_MAPPING}
                            onChange={value => {
                                onChange('currency', value === 'Google Ad Manager' ? 'CAD' : 'USD');
                                onChange('supply_source', value);
                            }}
                            placeholder={
                                flags.isEnabled('efb_35_curated_deals')
                                    ? 'Select an exchange'
                                    : 'Select a supply source'
                            }
                            disabled={getEnv() === 'spain'}
                        />
                        {showErrors && error && <FormHelperText error> {error} </FormHelperText>}
                    </div>
                );
            },
        },
        {
            label: 'Price',
            key: 'price_local',
            required: true,
            getInput: ({
                classes,
                onChange,
                showErrors,
                error,
                draft,
                environmentSettings,
                loading,
            }) => {
                return (
                    <Grid container direction={'column'}>
                        <Grid item xs={6} className={classes.dropdownContainer}>
                            <Select
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                value={draft.currency}
                                disabled
                                displayEmpty
                            >
                                <MenuItem disabled value="">
                                    Currency (automatically set)
                                </MenuItem>
                                {showCurrencyOptions(environmentSettings)}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldFormatter
                                key={loading}
                                initialValue={draft.price_local}
                                onChange={e => {
                                    if (_.isFinite(Number(e))) {
                                        return onChange('price_local', e);
                                    }
                                }}
                                formatIn={v => {
                                    if (v === 0) {
                                        return '';
                                    } else {
                                        return numeral(v).format('0,0.00');
                                    }
                                }}
                                shouldAllowChange={v => !/[a-zA-Z]/.test(v)}
                                formatOut={v => numeral(v).format('0,0.00')}
                                renderInput={({ value, onChange, onBlur }) => (
                                    <TextField
                                        required
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        placeholder={`Enter Price`}
                                        helperText={showErrors && error}
                                        error={showErrors && error ? true : false}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                );
            },
        },
        {
            label: 'Advertiser',
            key: 'advertiser',
            required: false,
            getInput: ({ onChange, classes, showErrors, error, draft, advertiserOptions }) => {
                return (
                    <div className={classes.dropdownContainer}>
                        <SingleSelect
                            value={draft.advertiserId}
                            options={advertiserOptions}
                            onChange={value => onChange('advertiserId', value)}
                            placeholder="Filter by advertiser"
                        />
                        {showErrors && error && <FormHelperText error> {error} </FormHelperText>}
                    </div>
                );
            },
        },
    ];

    return (
        <BlockLoadGroup size="medium" isLoading={loading}>
            <Dialog fullWidth maxWidth="xs" onClose={onClose} open={open}>
                <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <div>
                            <Typography variant="h6">
                                {isDetailsMode ? 'Edit Private Deal' : 'New Deal'}
                            </Typography>
                        </div>
                        <div>
                            <IconButton onClick={onClose} size="large">
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Box pt={2}>
                        <Grid container direction="column" spacing={2}>
                            {fields.map(field => {
                                if (isCurated && field.key === 'advertiser') {
                                    return null;
                                }

                                return (
                                    <Grid item xs={12} direction="column" key={field.key}>
                                        <FormLabel
                                            required={field.required}
                                            classes={{ asterisk: classes.red }}
                                        >
                                            {field.label}
                                        </FormLabel>

                                        {field.getInput({
                                            classes,
                                            value: draft[field.key],
                                            onChange,
                                            error: errors[field.key],
                                            showErrors,
                                            draft,
                                            deal,
                                            isDetailsMode,
                                            environmentSettings,
                                            loading,
                                            advertiserOptions,
                                        })}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                </DialogContent>

                <DialogActions className={classes.dialogPadding}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => dispatch(actions.submitForm(isDetailsMode, deal.id))}
                    >
                        {' '}
                        SAVE{' '}
                    </Button>
                </DialogActions>
            </Dialog>
        </BlockLoadGroup>
    );
};

export default connect(selector)(DealModal);
