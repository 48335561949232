import _ from 'lodash';
import { parse } from 'csv-parse/sync';
import { getValidImageSizeMapping } from 'forms/creative-form/constants';

const ALPHABET = 'abcdefghijklmnopqrstuvwxyz'.split('');

const DCM_HEADERS = [
    'Advertiser ID',
    'Advertiser Name',
    'Campaign ID',
    'Campaign Name',
    'Placement ID',
    'Placement External ID',
    'Site',
    'Placement Name',
    'Placement Compatibility',
    'Dimensions',
    'Start Date',
    'End Date',
    'Standard Tag',
    'Iframes/JavaScript Tag',
    'Internal Redirect Tag',
    'JavaScript Tag',
    'VAST 2.0 Pre-fetch Tag',
    'VAST 3.0 Pre-fetch Tag',
    'VAST 4.0 Pre-fetch Tag',
    'Keywords',
];
const DCM_HEADER_PLACEMENT_ID_COLUMN = 'F';
const DCM_HEADER_PLACEMENT_NAME_COLUMN = 'I';
const DCM_HEADER_DIMENSIONS_COLUMN = 'K';
const DCM_HEADER_JAVASCRIPT_TAG_COLUMN = 'Q';
const DCM_HEADER_ROW_START = 13;
const DCM_COLUMN_START = 'b';
const DCM_COLUMN_END = 'u';

const DCM_TRACKING_HEADER_PLACEMENT_NAME_COLUMN = 'I';
const DCM_TRACKING_HEADER_IMAGE_TAG_COLUMN = 'R';
const DCM_TRACKING_HEADER_CLICK_TAG_COLUMN = 'V';
const DCM_TRACKING_HEADER_ROW_START = 11;
const DCM_TRACKING_COLUMN_START = 'b';
const DCM_TRACKING_COLUMN_END = 'v';

const EYERETURN_HEADERS = ['Placement Name', 'Placement ID', 'Dimensions', 'Tag'];

const GENERIC_HEADERS = [
    'Name',
    'Size',
    'HTML Markup',
    'Landing Page',
    'Third-Party Clicktracker (optional)',
    'Third Party Pixels (optional, comma-separated)',
    'Requires MRAID (Yes or No)',
];

const FALSY_MRAID_VALUES = ['', 'no', 'false', 'n'];

const EYERETURN_PLACEMENT_NAME_COLUMN = 'A';
const EYERETURN_PLACEMENT_ID_COLUMN = 'B';
const EYERETURN_DIMENSIONS_COLUMN = 'C';
const EYERETURN_JAVASCRIPT_TAG_COLUMN = 'D';
const EYERETURN_HEADER_ROW_START = 11;
const EYERETURN_COLUMN_START = 'a';
const EYERETURN_COLUMN_END = 'd';

const getLineErrors = records => {
    const lineErrors = {};

    _.forEach(records, (record, index) => {
        const size = getValidImageSizeMapping()[`${record['Size']}`];
        if (!size) {
            lineErrors[`${record['Name']}_${record['Size']}`] = {
                name: record['Name'],
                line: index + 2,
            };
        }
    });
    return { lineErrors };
};

const isMraidFalsyValue = mraidValue => {
    return _.includes(FALSY_MRAID_VALUES, _.toLower(mraidValue));
};

export const parseEngageFrontTags = rawCsvData => {
    const csvHeaders = parse(rawCsvData, { relax_column_count: true })[0];

    const hasAllGenericHeaders = _.difference(GENERIC_HEADERS, csvHeaders).length === 0;

    if (hasAllGenericHeaders) {
        const parsedCsv = parse(rawCsvData, { columns: true });
        const { lineErrors } = getLineErrors(parsedCsv);
        const tags = _.map(parsedCsv, record => ({
            creativeKey: `${record['Name']}_${record['Size']}`,
            name: record['Name'],
            size: record['Size'],
            content_html: record['HTML Markup'],
            landing_page: record['Landing Page'],
            clickthrough_url: record['Third-Party Clicktracker (optional)'],
            third_party_pixels:
                record['Third Party Pixels (optional, comma-separated)'].length > 0
                    ? record['Third Party Pixels (optional, comma-separated)'].split(',')
                    : [],
            third_party_javascript_urls:
                record['Third Party Javascript (optional, comma-separated)'] &&
                record['Third Party Javascript (optional, comma-separated)'].length > 0
                    ? record['Third Party Javascript (optional, comma-separated)'].split(',')
                    : [],
            format: isMraidFalsyValue(record['Requires MRAID (Yes or No)'])
                ? 'custom_html'
                : 'mraid',
        }));
        return { tags, lineErrors };
    } else {
        return {
            error: {
                message: 'Missing or Incorrect Headers',
                headerDifference: _.difference(GENERIC_HEADERS, csvHeaders),
            },
        };
    }
};

const getTagsSheetKey = sheets => {
    return _.filter(_.keys(sheets), key => {
        const lowerCaseKey = _.lowerCase(key);
        return (
            lowerCaseKey === 'tags' ||
            (!_.includes(lowerCaseKey, 'tracking') && !_.includes(lowerCaseKey, 'legacy'))
        );
    })[0];
};

const parseThirdPartyPixels = ({ trackerTagsSheet, rowKey }) => {
    const value = trackerTagsSheet[`${DCM_TRACKING_HEADER_IMAGE_TAG_COLUMN}${rowKey}`].v;
    const thirdPartyPixel = value.split('"')[1];
    return thirdPartyPixel;
};

const parseClickThrough = ({ trackerTagsSheet, rowKey }) => {
    const clickThrough = trackerTagsSheet[`${DCM_TRACKING_HEADER_CLICK_TAG_COLUMN}${rowKey}`].v;
    return clickThrough;
};

const getAlphabetRange = (start, end) => {
    const enumeratedAlphabet = {};

    _.each(ALPHABET, (value, index) => {
        enumeratedAlphabet[value] = index;
    });

    return ALPHABET.slice(...[enumeratedAlphabet[start], enumeratedAlphabet[end] + 1]);
};

const getColumnKeys = ({ columnStart, columnEnd, rowNumber }) => {
    return _.map(
        getAlphabetRange(columnStart, columnEnd),
        letter => `${_.upperCase(letter)}${rowNumber}`
    );
};

const validateHeaders = (expectedHeaders, currentHeaders) => {
    const missingHeaders = _.difference(expectedHeaders, currentHeaders);
    const extraHeaders = _.difference(currentHeaders, expectedHeaders);

    const errors = [];

    if (extraHeaders.length > 0) {
        errors.push(`Unexpected headers: ${extraHeaders.join(', ')}`);
    }

    if (missingHeaders.length > 0) {
        errors.push(`Missing headers: ${missingHeaders.join(', ')}`);
    }

    if (errors.length > 0) {
        return {
            valid: false,
            error: errors.join('. '),
        };
    }

    return {
        valid: true,
    };
};

const getRowNumbers = (sheet, rowIndexStart) => {
    const output = [];
    const selectedColumn = 'B';

    let rowIndex = rowIndexStart;
    let rowKey = `${selectedColumn}${rowIndex}`;
    let row = sheet[rowKey];

    while (row) {
        output.push(rowIndex);
        rowIndex += 1;
        rowKey = `${selectedColumn}${rowIndex}`;
        row = sheet[rowKey];
    }

    return output;
};

const getDCMTrackerTagsSheetKey = sheets => {
    return _.filter(_.keys(sheets), key => {
        const lowerCaseKey = _.lowerCase(key);
        return lowerCaseKey === 'tracking ads';
    })[0];
};

export const parseEyeReturnTags = sheets => {
    const tags = [];
    let tagsSheet;
    const headers = [];
    const cellsRange = `${EYERETURN_COLUMN_START.toUpperCase()}${EYERETURN_HEADER_ROW_START}:${EYERETURN_COLUMN_END.toUpperCase()}${EYERETURN_HEADER_ROW_START}`;
    const expectedHeaders = `Expected JavaScript headers: ${EYERETURN_HEADERS.join(', ')}`;

    const tagsSheetKey = getTagsSheetKey(sheets);

    tagsSheet = sheets[tagsSheetKey];

    if (!tagsSheet) {
        return {
            error: 'missing tags sheet',
        };
    }

    try {
        const cells = getColumnKeys({
            columnStart: EYERETURN_COLUMN_START,
            columnEnd: EYERETURN_COLUMN_END,
            rowNumber: EYERETURN_HEADER_ROW_START,
        });

        for (const cell of cells) {
            if (_.isEmpty(tagsSheet[cell]?.v)) {
                return {
                    error: `Missing headers for cells: ${cellsRange}. ${expectedHeaders}`,
                };
            }
            headers.push(tagsSheet[cell].v);
        }
    } catch (error) {
        return {
            error: 'headers out of range',
        };
    }

    const validateHeadersResult = validateHeaders(EYERETURN_HEADERS, headers);

    if (!validateHeadersResult.valid) {
        return {
            error: validateHeadersResult.error,
        };
    }

    const rowNumbers = getRowNumbers(tagsSheet, EYERETURN_HEADER_ROW_START + 1);

    _.each(rowNumbers, rowKey => {
        tags.push({
            name: tagsSheet[`${EYERETURN_PLACEMENT_NAME_COLUMN}${rowKey}`].v,
            size: tagsSheet[`${EYERETURN_DIMENSIONS_COLUMN}${rowKey}`].v,
            content_html: tagsSheet[`${EYERETURN_JAVASCRIPT_TAG_COLUMN}${rowKey}`].v,
            third_party_vendors: ['eyereturn__tag__js'],
            creativeKey: tagsSheet[`${EYERETURN_PLACEMENT_ID_COLUMN}${rowKey}`].v,
        });
    });

    if (tags.length === 0) {
        return {
            error: 'no tags detected',
        };
    }

    return { tags };
};

export const parseDCMTags = sheets => {
    const tags = [];
    let tagsSheet;
    const headers = [];
    const cellsRange = `${DCM_COLUMN_START.toUpperCase()}${DCM_HEADER_ROW_START}:${DCM_COLUMN_END.toUpperCase()}${DCM_HEADER_ROW_START}`;
    const expectedHeaders = `Expected DCM headers: ${DCM_HEADERS.join(', ')}`;

    const tagsSheetKey = getTagsSheetKey(sheets);

    tagsSheet = sheets[tagsSheetKey];

    if (!tagsSheet) {
        return {
            error: 'missing tags sheet',
        };
    }

    try {
        const cells = getColumnKeys({
            columnStart: DCM_COLUMN_START,
            columnEnd: DCM_COLUMN_END,
            rowNumber: DCM_HEADER_ROW_START,
        });

        for (const cell of cells) {
            if (_.isEmpty(tagsSheet[cell]?.v)) {
                return {
                    error: `Missing headers for cells: ${cellsRange}. ${expectedHeaders}`,
                };
            }
            headers.push(tagsSheet[cell].v);
        }
    } catch (error) {
        return {
            error: 'headers out of range',
        };
    }

    const validateHeadersResult = validateHeaders(DCM_HEADERS, headers);

    if (!validateHeadersResult.valid) {
        return {
            error: validateHeadersResult.error,
        };
    }

    const rowNumbers = getRowNumbers(tagsSheet, DCM_HEADER_ROW_START + 1);

    _.each(rowNumbers, rowKey => {
        let landing_page = '';
        if (undefined !== tagsSheet[`${DCM_TRACKING_HEADER_CLICK_TAG_COLUMN}${rowKey}`]) {
            landing_page = tagsSheet[`${DCM_TRACKING_HEADER_CLICK_TAG_COLUMN}${rowKey}`].v;
        }

        tags.push({
            name: tagsSheet[`${DCM_HEADER_PLACEMENT_NAME_COLUMN}${rowKey}`].v,
            size: tagsSheet[`${DCM_HEADER_DIMENSIONS_COLUMN}${rowKey}`].v,
            content_html: tagsSheet[`${DCM_HEADER_JAVASCRIPT_TAG_COLUMN}${rowKey}`].v,
            third_party_vendors: ['dcm__tag__ins'],
            creativeKey: tagsSheet[`${DCM_HEADER_PLACEMENT_ID_COLUMN}${rowKey}`].v,
            landing_page,
        });
    });

    if (tags.length === 0) {
        return {
            error: 'no tags detected',
        };
    }

    return { tags };
};

export const parseDCMTrackerTags = sheets => {
    const tags = [];
    let trackerTagsSheet;

    const trackerTagsSheetKey = getDCMTrackerTagsSheetKey(sheets);

    trackerTagsSheet = sheets[trackerTagsSheetKey];

    if (!trackerTagsSheet) {
        return {
            error: 'Missing Tracking Ads sheet',
        };
    }

    try {
        _.forEach(
            getColumnKeys({
                columnStart: DCM_TRACKING_COLUMN_START,
                columnEnd: DCM_TRACKING_COLUMN_END,
                rowNumber: DCM_TRACKING_HEADER_ROW_START,
            }),
            key => trackerTagsSheet[key].v
        );
    } catch (error) {
        return {
            error: 'Headers out of range',
        };
    }

    const rowNumbers = getRowNumbers(trackerTagsSheet, DCM_TRACKING_HEADER_ROW_START + 1);

    _.each(rowNumbers, rowKey => {
        const thirdPartyPixel = parseThirdPartyPixels({ trackerTagsSheet, rowKey });

        const clickThrough = parseClickThrough({ trackerTagsSheet, rowKey });

        tags.push({
            name: trackerTagsSheet[`${DCM_TRACKING_HEADER_PLACEMENT_NAME_COLUMN}${rowKey}`].v,
            third_party_pixels: [thirdPartyPixel],
            clickthrough_url: clickThrough,
            // to be implemented in the 2nd phase for creative bulk edit
            //externalId: trackerTagsSheet[`${DCM_TRACKING_HEADER_PLACEMENT_ID_COLUMN}${rowKey}`].v,
        });
    });

    if (tags.length === 0) {
        return {
            error: 'No tags detected',
        };
    }
    return { tags };
};

export const getTagsByCreativeVendor = ({ rawSheets, creativeVendor }) => {
    let output = {};

    if (creativeVendor === 'eyereturn__tag__js') {
        output = parseEyeReturnTags(rawSheets);
    } else if (creativeVendor === 'dcm__tag__ins') {
        output = parseDCMTags(rawSheets);
    }

    return output;
};
