import React from 'react';
/** import third-party libraries in the section above, keep the ascending order */

import Box from '@mui/material/Box';
/** import mui components in the section above, keep the ascending order */

import { IncludeExcludeLayout } from 'widgets-v6/layout';
import { MultiSelect } from 'widgets-v6/select';
/** import widgets components in the section above, keep the ascending order */

import { getExchangeLabelValueOptions } from 'states/resources/ads/business-logic';
import { WarningText } from 'forms/ad-form/modules/cross-platform-setup-warning';
/** import classes and others in the section above, keep the ascending order */

export const TargetExchangesBlockedUI = ({
    campaignType,
    exchangesExcludeValue,
    exchangeWarning,
    isCrossPlatformCampaign,
    onFieldChange,
    errors,
}) => {
    const EXCHANGE_OPTIONS = getExchangeLabelValueOptions({ type: campaignType });
    const MAX_ALLOWED_EXCHANGES = EXCHANGE_OPTIONS.length - 1;
    const EXCHANGE_HELPER_TEXT =
        MAX_ALLOWED_EXCHANGES === 0
            ? 'This campaign is only compatible with one exchange, which cannot be blocked'
            : `You can block up to ${MAX_ALLOWED_EXCHANGES} options. Blocking all exchanges would prevent the campaign from running`;

    return (
        <React.Fragment>
            <IncludeExcludeLayout type="Include" marginBottom>
                {' '}
                {/*margin Bottom to check*/}
                All compatible and new exchanges
            </IncludeExcludeLayout>
            <IncludeExcludeLayout type="Exclude">
                <MultiSelect
                    value={exchangesExcludeValue}
                    onChange={onFieldChange}
                    options={EXCHANGE_OPTIONS}
                    maxAllowedOptions={MAX_ALLOWED_EXCHANGES}
                    preHelperText={EXCHANGE_HELPER_TEXT}
                    placeholder="Select exchanges to exclude"
                />
            </IncludeExcludeLayout>
            {isCrossPlatformCampaign && exchangeWarning?.hasWarning > 0 && (
                <Box marginTop={1} marginLeft={4}>
                    <WarningText
                        message={exchangeWarning.message}
                        title={exchangeWarning.title}
                        severity={exchangeWarning.severity}
                    />
                </Box>
            )}
            {errors?.exchanges_blocked && (
                <Box marginTop={1} marginLeft={4}>
                    <WarningText message={errors.exchanges_blocked} severity={'hard'} />
                </Box>
            )}
            {errors?.exchanges_blocked_ui && (
                <Box marginTop={1} marginLeft={4}>
                    <WarningText message={errors.exchanges_blocked_ui} severity={'hard'} />
                </Box>
            )}
        </React.Fragment>
    );
};
